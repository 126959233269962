import React from 'react'
import { Layout } from '../component/Layout'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { PageProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  contant: {
    marginTop: theme.spacing(10),
    fontSize: theme.typography.pxToRem(50),
    marginBottom: theme.spacing(10),
  },
}))

const NotFound: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Layout location={location} title={'Kretz'}>
      <Box className={classes.contant}>{intl.formatMessage({ id: 'PageNotFound' })}</Box>
    </Layout>
  )
}

export default NotFound
